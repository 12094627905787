import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style';

const Header = () => (
	// <header className={style.header}>
	// 	<Link href="/"><h1>PurpleBin</h1></Link>
	// 	<nav>
	// 		<Link activeclassNameName={style.active} href="https://www.fxhash.xyz/u/%40PurplebinDAO">MINT</Link>
	// 		<Link activeclassNameName={style.active} href="/contact">Contact me</Link>
	// 	</nav>
	// </header>
	 <header className="Header__SiteHeader-sc-1ffv7i6-0 dItzqG sticky-header  ">
	 <div className="container-fluid">
			<nav className="navbar px-0 px-md-3 site-navbar offcanvas-active navbar-expand-lg navbar-light">
				<div className="brand-logo">
					<a aria-current="page" className="" href="/">
						<h4 color="dark" className="Title__CardTitle-wo72v5-3 Title__CardTitleLg-wo72v5-5 biPebc mb-0">
				PurpleBin
						</h4>
					</a>
				</div>
				<div className="collapse navbar-collapse">
					<div className="navbar-nav ml-lg-auto mr-3">
						<nav>
							<ul className="Header__Menu-sc-1ffv7i6-2 fnluip navbar-nav d-none d-lg-flex">
								<li className="nav-item"><a className="nav-link" href="https://www.fxhash.xyz/u/PurplebinDAO" target="_blank" rel="noreferrer">Mint</a></li>
								{/* <li className="nav-item"><a className="nav-link" href="/#">contact</a></li>
								<li className="nav-item"><a className="nav-link" href="https://uxtheme.net/theme-support" target="_blank" rel="noreferrer">support</a></li> */}
								<li className="nav-item"><a className="nav-link" href="https://twitter.com/PurplebinDAO" target="_blank" rel="noreferrer">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
										<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
									</svg>
								</a></li>

							
							</ul>
						</nav>
					</div>
				</div>
				<button
					className="Header__ToggleButton-sc-1ffv7i6-1 bLVlJJ navbar-toggler btn-close-off-canvas ml-3 "
					type="button"
					data-toggle="collapse"
					data-target="#mobile-menu"
					aria-controls="mobile-menu"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<i className="icon icon-menu-34 icon-burger d-block" />
				</button>
			</nav>
	 </div>
	</header>
);

export default Header;
